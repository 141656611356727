// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-games-js": () => import("./../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-wormhunt-privacy-js": () => import("./../src/pages/wormhunt/privacy.js" /* webpackChunkName: "component---src-pages-wormhunt-privacy-js" */),
  "component---src-pages-wormhunt-terms-and-conditions-js": () => import("./../src/pages/wormhunt/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-wormhunt-terms-and-conditions-js" */)
}

